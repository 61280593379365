.team-container {
    background-color: var(--orange-color);
}

.about-container {
    background-color: var(--navy-color);
}

.home-title {
    margin-bottom: 0px;
}

.home-subtitle {
    margin-bottom: 60px;
}

.text-container {
    width: 500px;
}

.about-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: -20px;
    margin-right: -20px;
}

.about-grid-item {
    margin: 20px;
}

@media screen and (max-width: 540px) {
    .about-grid {
        grid-template-columns: 1fr;
    }
}