.active {
    color: black;
    border: 0px;
    border-bottom: 3px;
    border-color: var(--pink-color);
    border-style: solid;
}

.item {
    height: 25px;
    /* margin-top: 1px; */
}

.item:hover {
    color: black;
}

.brand-text {
    font-family: 'Kumbh Sans';
    font-weight: bold;
}

.item-text {
    font-family: 'Space Mono';
}

.navbar-logo {
    margin-bottom: .25em;
    margin-right: .2em;
}